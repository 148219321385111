import React from "react";
import useLocalization from "../hooks/useLocalization";
import OgImg from "../assets/images/raster/components/common/mainscreen.png";
import Layout from "../layouts/LayoutDefault";
import Seo from "../components/Seo";
import HomeIntro from "../components/about/HomeIntro";
import Partners from "../components/about/Partners";
import HiringBlock from "../components/about/HiringBlock";
import Contacts from "../components/about/Contacts";
import Information from "../components/about/Information";

const PgA = () => {
  const currentLocale = useLocalization() || "us";

  return (
    <Layout customClass="PgA">
      <Seo
        title="AMZScout: About Us"
        description="Here you can find a short user’s guide for our product | AMZScout"
        page="about"
        manifest="browserconfig.xml"
        ogImg={OgImg}
      />
      <HomeIntro />
      <Information currentLocale={currentLocale} />
      <Partners />
      <HiringBlock />
      <Contacts currentLocale={currentLocale} />
    </Layout>
  );
};

export default PgA;
