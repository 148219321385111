import SvgOne from "../../../assets/images/vector/pages/about/HiringBlock/PgA-HiringBlock-one.svg"
import SvgTwo from "../../../assets/images/vector/pages/about/HiringBlock/PgA-HiringBlock-two.svg"
import SvgThree from "../../../assets/images/vector/pages/about/HiringBlock/PgA-HiringBlock-three.svg"
import SvgFour from "../../../assets/images/vector/pages/about/HiringBlock/PgA-HiringBlock-four.svg"
import SvgFive from "../../../assets/images/vector/pages/about/HiringBlock/PgA-HiringBlock-five.svg"

const PG_A_HIRING_BLOCK_CONTENT = [
  {
    img: SvgOne,
    text: "100% remote",
    mod: "one",
  },
  {
    img: SvgTwo,
    text: "Flexible work hours",
    mod: "two",
  },
  {
    img: SvgThree,
    text: "Education benefits",
    mod: "three",
  },
  {
    img: SvgFour,
    text: "Open vacation policy",
    mod: "four",
  },
  {
    img: SvgFive,
    text: "Company retreats",
    mod: "five",
  },
]

export default PG_A_HIRING_BLOCK_CONTENT
