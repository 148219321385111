import React from "react";
import PG_A_HIRING_BLOCK_CONTENT from "./index.content";
import "./index.scss";

const HiringBlock = () => {
  return (
    <div className="PgA-HiringBlock">
      <div className="container">
        <h2 className="PgA-HiringBlock__title">We are hiring</h2>
        <p className="PgA-HiringBlock__text">
          AMZScout products are built by a remote team of people who genuinely
          love freedom, flexibility and love working together
        </p>
        <ul className="PgA-HiringBlock-list">
          {PG_A_HIRING_BLOCK_CONTENT.map(({ img, text, mod }) => (
            <li
              className={`PgA-HiringBlock-item PgA-HiringBlock-item_${mod}`}
              key={mod}
            >
              <img src={img} alt={`${mod}`} />
              <p>{text}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default HiringBlock;
