import React from "react";
import logoSVG from "../../../assets/images/vector/pages/about/HomeIntro/PgA-HomeIntro-logo.svg";
import "./index.scss";

const HomeIntro = () => {
  return (
    <div className="PgA-HomeIntro">
      <div className="container">
        <div className="PgA-HomeIntro__info">
          <h1 className="PgA-HomeIntro__title">
            <span>Our mission is</span> to help Amazon sellers successfully
            launch their business, maintain profitability, and multiply their
            income
          </h1>
          <img className="PgA-HomeIntro__logo" src={logoSVG} alt="logo" />
        </div>
      </div>
    </div>
  );
};

export default HomeIntro;
