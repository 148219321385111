import React from "react"

const PG_A_INFORMATION_CONTENT = {
  us: [
    "The AMZScout Corp. was established in the U.S. in 2017 in Philadelphia, Pennsylvania, with a vision of helping struggling Amazon sellers get a handle on the market, minimize product launch risks, and grow a sustainable income.",
    "The AMZScout user count has grown past 1,000,000 accounts. It is one of the top tools for Amazon sellers worldwide, with customers ranging from first-time sellers, to established companies like RedBull, Disney, and CASIO.",
    "AMZScout became a multinational company with a mission of helping Amazon sellers increase their profits by providing them with accurate market data for investment decisions.",
  ],
  eu: [
    "The AMZScout Corp. was established in the U.S. in 2017 in Philadelphia, Pennsylvania, with a vision of helping struggling Amazon sellers get a handle on the market, minimize product launch risks, and grow a sustainable income.",
    "The AMZScout user count has grown past 1,000,000 accounts. It is one of the top tools for Amazon sellers worldwide, with customers ranging from first-time sellers, to established companies like RedBull, Disney, and CASIO.",
    "AMZScout became a multinational company with a mission of helping Amazon sellers increase their profits by providing them with accurate market data for investment decisions.",
    "AMZScout is operated by AMZScout Corp., a U.S. Pennsylvania corporation and Growthco DMCC, a company incorporated in United Arab Emirates, licence number DMCC-818075.",
  ],
}
export default PG_A_INFORMATION_CONTENT
