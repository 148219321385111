import React from "react";
import PartnerBrand from "../../common/PartnerBrand";
import "./index.scss";

const Partners = () => {
  return (
    <div className="PgA-Partners">
      <h2 className="PgA-Partners__title">Our customers</h2>
      <div className="PgA-Partners__text">
        We’re proud to see AMZScout at work in thousands of corporate and home
        offices
      </div>
      <PartnerBrand />
    </div>
  );
};

export default Partners;
